var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('v-card', {
    attrs: {
      "flat": "",
      "outlined": ""
    }
  }, [_c('v-card-title', [_vm._v(" Competitive Profile "), _c('v-spacer'), _vm.edit ? _c('v-dialog', {
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        _vm.add = false;
      }
    },
    scopedSlots: _vm._u([{
      key: "activator",
      fn: function (_ref) {
        var on = _ref.on,
          attrs = _ref.attrs;
        return [_c('v-btn', _vm._g(_vm._b({
          attrs: {
            "icon": ""
          }
        }, 'v-btn', attrs, false), on), [_c('img', {
          attrs: {
            "src": require('./../assets/images/icon/Add.svg')
          }
        })])];
      }
    }], null, false, 2668002262),
    model: {
      value: _vm.add,
      callback: function ($$v) {
        _vm.add = $$v;
      },
      expression: "add"
    }
  }, [_c('v-card', [_c('v-card-title', [_vm._v("Add Competitive Profile")]), _c('v-card-text', [_c('v-row', [_c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-select', {
    attrs: {
      "items": _vm.allowedSites,
      "label": "Site",
      "rules": [_vm.rules.required]
    },
    on: {
      "change": _vm.setSite
    },
    model: {
      value: _vm.s,
      callback: function ($$v) {
        _vm.s = $$v;
      },
      expression: "s"
    }
  })], 1), _c('v-col', {
    attrs: {
      "cols": "12",
      "sm": "6",
      "md": "4"
    }
  }, [_c('v-text-field', {
    attrs: {
      "label": "Handle"
    },
    on: {
      "change": _vm.setHandle
    }
  })], 1)], 1)], 1), _c('v-card-actions', [_c('v-spacer'), _c('v-btn', {
    attrs: {
      "loading": _vm.isContentSaved,
      "disbled": _vm.isContentSaved,
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": _vm.addNewSite
    }
  }, [_vm._v("Save")]), _c('v-btn', {
    attrs: {
      "color": "blue darken-1",
      "text": ""
    },
    on: {
      "click": function ($event) {
        _vm.add = false;
      }
    }
  }, [_vm._v("CANCEL")])], 1)], 1)], 1) : _vm._e()], 1), _c('v-card-text', [_vm.isSelfProfile && !_vm.edit && (!_vm.competitiveProfiles || _vm.competitiveProfiles.length === 0) ? _c('v-row', [_c('v-col', [_vm._v(" No competitive sites added. Please "), _c('router-link', {
    attrs: {
      "to": "/profile/edit"
    }
  }, [_vm._v("edit profile")]), _vm._v(" and add a site. ")], 1)], 1) : _vm._e(), _vm._l(_vm.competitiveProfiles, function (expr, index) {
    return _c('v-row', {
      key: index
    }, [_c('v-col', [_c('v-card', {
      attrs: {
        "color": _vm.siteMap[_vm.siteKeys[expr.getCompetitiveSite()]].tileColor,
        "outlined": "",
        "tile": ""
      }
    }, [_c('v-container', {
      staticClass: "text-center",
      style: {
        width: 'auto'
      },
      attrs: {
        "row": ""
      }
    }, [_c('v-col', {
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_c('v-avatar', {
      attrs: {
        "width": "100",
        "rounded": ""
      }
    }, [_c('v-img', {
      attrs: {
        "max-height": "50",
        "contain": "",
        "src": require('./../assets/images/social/' + _vm.siteName(expr) + '.png')
      }
    })], 1)], 1), _c('v-col', {
      staticClass: "text-capitalize",
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_vm._v(" " + _vm._s(_vm.siteKeys[expr.getCompetitiveSite()].toLowerCase().split('_').join(' ')) + " ")]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_c('a', {
      staticClass: "router_link",
      attrs: {
        "href": _vm.siteMap[_vm.siteKeys[expr.getCompetitiveSite()]].profileLinkPrefix + expr.getHandle(),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(expr.getHandle()) + " ")])]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_vm._v(" Rating: " + _vm._s(expr.getRating()) + " ")]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_vm._v(" Problems Solved: " + _vm._s(expr.getSolvedProblems()) + " ")]), _c('v-col', {
      attrs: {
        "cols": "12",
        "md": "2",
        "lg": "2"
      }
    }, [_c('v-chip', {
      attrs: {
        "color": expr.getVerified() ? 'success' : '#ddd'
      }
    }, [_vm._v(_vm._s(expr.getVerified() ? 'Verified' : 'Unverified'))])], 1)], 1), _vm.edit ? _c('v-row', {
      staticClass: "text-center"
    }, [!expr.getVerified() && expr.getCompetitiveSite() != 0 && expr.getVerificationList() ? _c('v-col', {
      staticClass: "text-left pl-5 font-italic text--secondary"
    }, [_vm._v(" " + _vm._s(_vm.verifyLinkStr[expr.getVerificationList()[0].getVerificationType()].pre) + " "), _c('b', [_vm._v(" " + _vm._s(expr.getVerificationList()[0].getVerificationType() == 3 ? expr.getVerificationList()[0].getCodeLanguage() : expr.getVerificationList()[0].getVerificationString()) + " ")]), _vm._v(" " + _vm._s(_vm.verifyLinkStr[expr.getVerificationList()[0].getVerificationType()].suf) + " "), _c('a', {
      attrs: {
        "href": expr.getVerificationList()[0].getUrl(),
        "target": "_blank"
      }
    }, [_vm._v(" " + _vm._s(expr.getVerificationList()[0].getVerificationType() == 3 ? 'problem' : expr.getVerificationList()[0].getVerificationType() == 2 ? 'post' : 'profile') + " ")]), _vm._v(". ")]) : _vm._e(), _vm.edit ? _c('v-col', {
      staticClass: "text-right"
    }, [_c('v-btn', {
      staticClass: "ma-2",
      attrs: {
        "loading": _vm.isContentDeleted,
        "disbled": _vm.isContentDeleted,
        "color": "danger",
        "dark": ""
      },
      on: {
        "click": function ($event) {
          return _vm.removeProfile(index);
        }
      }
    }, [_vm._v("delete")]), expr.getCompetitiveSite() && expr.getVerificationList() ? _c('v-btn', {
      staticClass: "mx-2 text-right",
      attrs: {
        "loading": _vm.isContentVerified,
        "disabled": _vm.isContentVerified,
        "color": "accent"
      },
      on: {
        "click": function ($event) {
          _vm.verifyProfile(_vm.siteKeys[expr.getCompetitiveSite()]);
        }
      }
    }, [_vm._v("Verify")]) : _vm._e()], 1) : _vm._e()], 1) : _vm._e()], 1)], 1)], 1);
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }