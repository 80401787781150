<template>
  <v-dialog v-model="edit" @keydown.esc="edit = false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn icon v-bind="attrs" v-on="on">
        <img :src="require('./../assets/images/icon/Edit.svg')" />
      </v-btn>
    </template>
    <v-card>
      <v-card-title>Edit Experience</v-card-title>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Title"
              :value="expr && expr.getTitle()"
              @change="editTitle"
            ></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Company"
              :value="expr && expr.getCompany()"
              @change="editCompany"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="Start Date"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field label="End Date"></v-text-field>
          </v-col>
          <v-col cols="12" sm="6" md="4">
            <v-text-field
              label="Location"
              :value="expr && expr.getLocation()"
              @change="editLocation"
            ></v-text-field>
          </v-col>
        </v-row>
        <v-row>
          <v-col>
            <v-textarea
              label="Description"
              counter
              maxlength="120"
              full-width
              single-line
            >
            </v-textarea>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="accent" text @click="updateProfile">Save</v-btn>
        <v-btn color="accent" text @click="edit = false">CANCEL</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>
<script>
export default {
  data: function () {
    return {
      edit: false,
      rules: {
        required: (v) => !!v || 'Required',
      },
    }
  },
  methods: {
    editTitle(title) {
      this.$emit('editTitle', title)
    },
    editCompany(company) {
      this.$emit('editCompany', company)
    },
    editLocation(location) {
      this.$emit('editLocation', location)
    },
    updateProfile() {
      //this.$emit("updateProfile");
      this.edit = false
    },
  },
  props: {
    expr: {
      type: Object,
      required: true,
    },
  },
}
</script>
