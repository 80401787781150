<template>
  <v-card flat outlined>
    <v-card-title>
      Competitive Profile
      <v-spacer></v-spacer>

      <v-dialog v-if="edit" v-model="add" @keydown.esc="add = false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <img :src="require('./../assets/images/icon/Add.svg')" />
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add Competitive Profile</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-select
                  :items="allowedSites"
                  label="Site"
                  v-model="s"
                  :rules="[rules.required]"
                  @change="setSite"
                ></v-select>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field label="Handle" @change="setHandle"></v-text-field>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              :loading="isContentSaved"
              :disbled="isContentSaved"
              color="blue darken-1"
              text
              @click="addNewSite"
              >Save</v-btn
            >
            <v-btn color="blue darken-1" text @click="add = false"
              >CANCEL</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-card-text>
      <v-row
        v-if="
          isSelfProfile &&
          !edit &&
          (!competitiveProfiles || competitiveProfiles.length === 0)
        "
      >
        <v-col>
          No competitive sites added. Please
          <router-link to="/profile/edit">edit profile</router-link> and add a
          site.
        </v-col>
      </v-row>
      <v-row v-for="(expr, index) in competitiveProfiles" v-bind:key="index">
        <v-col>
          <v-card
            :color="siteMap[siteKeys[expr.getCompetitiveSite()]].tileColor"
            outlined
            tile
          >
            <v-container :style="{ width: 'auto' }" row class="text-center">
              <v-col cols="12" md="2" lg="2">
                <v-avatar width="100" rounded>
                  <v-img
                    max-height="50"
                    contain
                    :src="
                      require('./../assets/images/social/' +
                        siteName(expr) +
                        '.png')
                    "
                  ></v-img>
                </v-avatar>
              </v-col>
              <v-col cols="12" md="2" lg="2" class="text-capitalize">
                {{
                  siteKeys[expr.getCompetitiveSite()]
                    .toLowerCase()
                    .split('_')
                    .join(' ')
                }}
              </v-col>
              <v-col cols="12" md="2" lg="2">
                <a
                  :href="
                    siteMap[siteKeys[expr.getCompetitiveSite()]]
                      .profileLinkPrefix + expr.getHandle()
                  "
                  target="_blank"
                  class="router_link"
                >
                  {{ expr.getHandle() }}
                </a>
              </v-col>
              <v-col cols="12" md="2" lg="2">
                Rating: {{ expr.getRating() }}
              </v-col>
              <v-col cols="12" md="2" lg="2">
                Problems Solved: {{ expr.getSolvedProblems() }}
              </v-col>
              <v-col cols="12" md="2" lg="2">
                <v-chip :color="expr.getVerified() ? 'success' : '#ddd'">{{
                  expr.getVerified() ? 'Verified' : 'Unverified'
                }}</v-chip>
              </v-col>
            </v-container>
            <v-row v-if="edit" class="text-center">
              <v-col
                v-if="
                  !expr.getVerified() &&
                  expr.getCompetitiveSite() != 0 &&
                  expr.getVerificationList()
                "
                class="text-left pl-5 font-italic text--secondary"
              >
                {{
                  verifyLinkStr[
                    expr.getVerificationList()[0].getVerificationType()
                  ].pre
                }}
                <b>
                  {{
                    expr.getVerificationList()[0].getVerificationType() == 3
                      ? expr.getVerificationList()[0].getCodeLanguage()
                      : expr.getVerificationList()[0].getVerificationString()
                  }}
                </b>
                {{
                  verifyLinkStr[
                    expr.getVerificationList()[0].getVerificationType()
                  ].suf
                }}
                <a
                  :href="expr.getVerificationList()[0].getUrl()"
                  target="_blank"
                >
                  {{
                    expr.getVerificationList()[0].getVerificationType() == 3
                      ? 'problem'
                      : expr.getVerificationList()[0].getVerificationType() == 2
                      ? 'post'
                      : 'profile'
                  }} </a
                >.
              </v-col>
              <v-col class="text-right" v-if="edit">
                <v-btn
                  :loading="isContentDeleted"
                  :disbled="isContentDeleted"
                  class="ma-2"
                  color="danger"
                  dark
                  @click="removeProfile(index)"
                  >delete</v-btn
                >
                <v-btn
                  :loading="isContentVerified"
                  :disabled="isContentVerified"
                  v-if="expr.getCompetitiveSite() && expr.getVerificationList()"
                  class="mx-2 text-right"
                  color="accent"
                  @click="verifyProfile(siteKeys[expr.getCompetitiveSite()])"
                  >Verify</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import ExperienceDailog from '../components/ExperienceDailog'
import { mapGetters } from 'vuex'

export default {
  data: function () {
    return {
      isContentSaved: false,
      s: '',
      add: false,
      rules: {
        required: (v) => !!v || 'Required',
      },
      allowedSites: ['CODEFORCES', 'CODECHEF', 'SPOJ'],
      competitiveProfile:
        new proto.io.codedrills.proto.user.CompetitiveProfile(),
      siteMap: {
        CODECHEF: {
          tileColor: '#FFB163',
          profileLinkPrefix: 'https://www.codechef.com/users/',
        },
        CODEFORCES: {
          tileColor: '#aeadd3',
          profileLinkPrefix: 'https://www.codeforces.com/profile/',
        },
        ATCODER: {
          tileColor: '#cfcfcf',
          profileLinkPrefix: 'https://www.atcoder.jp/users/',
        },
        HACKERRANK: {
          tileColor: '#75e99d',
          profileLinkPrefix: 'https://www.hackerrank.com/',
        },
        HACKEREARTH: {
          tileColor: '#98a4c8',
          profileLinkPrefix: 'https://www.hackerearth.com/@',
        },
        SPOJ: {
          tileColor: '#b4d0e8',
          profileLinkPrefix: 'https://www.spoj.com/users/',
        },
        TOPCODER: {
          tileColor: '#89dcfd',
          profileLinkPrefix: 'https://www.topcoder.com/members/',
        },
        ICPC: {
          tileColor: '#f7cb79',
          profileLinkPrefix: 'https://icpc.global/private/profile/',
        },
        UNKNOWN_COMPETITIVE_SITE: {
          tileColor: '#CFD8DC',
          profileLinkPrefix: '/',
        },
      },
      verifyLinkStr: {
        1: {
          pre: `Add string`,
          suf: `anywhere in your`,
        },
        4: {
          pre: `Add string`,
          suf: `to your last or first name in your`,
        },
        2: {
          pre: `Post a comment with string`,
          suf: `to this`,
        },
        3: {
          pre: `Submit a solution in`,
          suf: `to this`,
        },
      },
    }
  },
  props: {
    competitiveProfiles: {
      type: Array,
      required: true,
    },
    edit: {
      type: Boolean,
    },
    isContentVerified: {
      type: Boolean,
    },
    isContentDeleted: {
      type: Boolean,
    },
    isSelfProfile: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  methods: {
    setSite(site) {
      if (site) this.competitiveProfile.setCompetitiveSite(this.site[site])
    },
    setHandle(handle) {
      if (handle) this.competitiveProfile.setHandle(handle)
    },
    addNewSite() {
      this.isContentSaved = true
      //console.log("CP ..", this.competitiveProfile.toObject());
      if (this.competitiveProfile.getCompetitiveSite())
        this.$emit('addCPProfile', this.competitiveProfile)
      this.add = false
      this.isContentSaved = false
    },
    removeProfile(i) {
      console.log(`calling removeProfile...`)
      this.$emit('removeProfile', i)
    },

    verifyProfile(site) {
      console.log(`calling verifyProfile...`, site)
      this.$emit('verifyProfile', site)
    },

    siteName(expr) {
      return this.siteKeys[expr.getCompetitiveSite()]
    },
  },
  computed: {
    ...mapGetters('user', ['siteKeys', 'site']),
  },
  components: {
    ExperienceDailog,
  },
}
</script>

<style scoped>
.router_link {
  color: inherit;
  text-decoration: none;
}
</style>
