<template>
  <v-card flat outlined>
    <v-card-title>
      Experience
      <v-spacer></v-spacer>

      <v-dialog v-model="add" @keydown.esc="add = false">
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <img :src="require('./../assets/images/icon/Add.svg')" />
          </v-btn>
        </template>
        <v-card>
          <v-card-title>Add Experience</v-card-title>
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Title"
                  :rules="[rules.required]"
                  @change="setTitle"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Company"
                  @change="setCompany"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Start Date"
                  @change="setStartDate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="End Date"
                  @change="setEndDate"
                ></v-text-field>
              </v-col>
              <v-col cols="12" sm="6" md="4">
                <v-text-field
                  label="Location"
                  @change="setLocation"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <v-textarea
                  label="Description"
                  counter
                  maxlength="120"
                  full-width
                  single-line
                >
                </v-textarea>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="addNewExperience"
              >Save</v-btn
            >
            <v-btn color="blue darken-1" text @click="add = false"
              >CANCEL</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-card-title>

    <v-card-text>
      <v-row v-for="(expr, index) in experiences" v-bind:key="index">
        <v-col cols="5">
          <v-card flat>
            <div class="d-flex justify-space-between">
              <v-avatar class="ma-3" size="125" tile>
                <img :src="require('./../assets/images/icon/Work.svg')" />
              </v-avatar>
              <div>
                <v-card-title class="text-caption-1">{{
                  expr.getCompany()
                }}</v-card-title>
                <v-card-subtitle>
                  {{ expr.getTitle() }} <br />
                  Sept 2019 - Present | 9 mos <br />
                  {{ expr.getLocation() }}
                </v-card-subtitle>
              </div>
            </div>
          </v-card>
        </v-col>
        <v-col cols="7" class="text-right">
          <v-spacer></v-spacer>
          <experience-dailog
            :expr="expr"
            @editTitle="(title) => editTitle(title, index)"
            @editCompany="(company) => editCompany(company, index)"
            @editLocation="(location) => editLocation(location, index)"
            @updateProfile="editProfile"
          >
          </experience-dailog>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>
import user from 'codedrills_proto/io/codedrills/proto/user/user_service_grpc_web_pb'
import ExperienceDailog from '../components/ExperienceDailog'

export default {
  data: function () {
    return {
      edit: false,
      add: false,
      rules: {
        required: (v) => !!v || 'Required',
      },
      experience: new proto.io.codedrills.proto.user.Experience(),
    }
  },
  props: {
    experiences: {
      type: Array,
      required: true,
    },
  },
  methods: {
    editTitle(title, index) {
      this.$emit('updateTitle', title, index)
    },
    editCompany(company, index) {
      this.$emit('updateCompany', company, index)
    },
    editLocation(location, index) {
      this.$emit('updateLocation', location, index)
    },
    editProfile() {
      //this.$emit("updateProfile");
    },
    setTitle(title) {
      if (title) this.experience.setTitle(title)
    },
    setCompany(company) {
      if (company) this.experience.setCompany(company)
    },
    setStartDate(startDate) {
      if (startDate) this.experience.setStart(startDate)
    },
    setEndDate(endDate) {
      if (endDate) this.experience.setEnd(endDate)
    },
    setLocation(location) {
      if (location) this.experience.setLocation(location)
    },
    addNewExperience() {
      if (this.experience.getTitle()) this.$emit('change', this.experience)
      this.add = false
    },
  },
  async mounted() {
    console.log('Experiences', this.experiences)
  },
  components: {
    ExperienceDailog,
  },
}
</script>
